<template>
  <body>
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"
    >
      <div class="dashboard-wrapper page-wrapper">
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h4 class="page-title">Edit Company</h4>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-xlg-9 col-md-12">
              <ValidationObserver ref="simpleRules">
                <form
                  class="form-horizontal form-material"
                  v-on:submit.prevent="validationForm"
                >
                  <div class="card profile-card">
                    <div class="card-body">
                  

              

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Name</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Company Name"
                            rules="required|max:50"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="companyName"
                                v-model="companies.name"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Company Name"
                              />
                            </b-input-group>
                            <!-- <input type="text" v-model.trim="name"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"> -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Email</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Email"
                            rules="required|email|max:50"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="MailIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="fh-email"
                                v-model="companies.email"
                                type="email"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Email"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">About</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="About"
                            rules="required|max:5000"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-textarea
                                id="fh-email"
                                v-model="companies.about"
                                :state="errors.length > 0 ? false : null"
                                placeholder="About"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>
                      
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Currency</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Currency"
                            rules="required"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="DollarSignIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="fh-Currency"
                                v-model="companies.Currency"
                                type="Currency"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Currency"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>
                      <!-- Logo Preview -->

                      <b-col cols="12">
                        <b-img
                          thumbnail
                          fluid
                          :src="previewLogoImg"
                          :class="previewLogoImg.length <= 0 ? 'd-none' : null"
                          alt="Image"
                        ></b-img>
                      </b-col>

                      <!-- new code to Image (Logo) fire base -->
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Logo</label>
                        <div class="col-md-12 border-bottom p-0">
                          <div class="formcta_wrapper">
                            <div>
                              <validation-provider
                                name="Company Logo"
                                rules="image|size:100"
                                #default="{ errors }"
                              >
                                <b-input-group
                                  class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                                >
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="ImageIcon" />
                                  </b-input-group-prepend>
                                  <b-form-file
                                    v-model="comLogoImg"
                                    ref="comLogInput"
                                    @change="onComLogoChange"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end logo -->

                      <!-- Background Preview -->

                      <b-col cols="12">
                        <b-img
                          thumbnail
                          fluid
                          :src="previewBackImg"
                          :class="previewBackImg.length <= 0 ? 'd-none' : null"
                          alt="Image"
                        ></b-img>
                      </b-col>

                      <!-- company Background image firebase -->
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Company Backgound Image</label>
                        <div class="col-md-12 border-bottom p-0">
                          <div class="formcta_wrapper">
                            <div>
                              <validation-provider
                                name="Company Backgound Logo"
                                rules="image|size:100"
                                #default="{ errors }"
                              >
                                <b-input-group
                                  class="input-group-merge"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                                >
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="ImageIcon" />
                                  </b-input-group-prepend>
                                  <b-form-file
                                    v-model="comBackgroundImg"
                                    ref="combackInput"
                                    @change="onComBackChange"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- End company Background image firebase -->

               

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Phone No</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Phone No"
                            rules="required|max:20"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="phoneNum"
                                v-model="companies.phone_no"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Phone No"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Website URL</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Website URL"
                            rules="required|max:200"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="BriefcaseIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="website"
                                v-model="companies.website"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Website URL"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                 
                    </div>
                  </div>

                  <div class="card profile-card">
                    <div class="card-body">
                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Title</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Meta Title"
                            rules="required|max:200"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                v-model.trim="companies.meta_title"
                                :state="errors.length > 0 ? false : null"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Description</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Meta Description"
                            rules="required|max:200"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-textarea
                                v-model.trim="companies.meta_description"
                                :state="errors.length > 0 ? false : null"
                                rows="5"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Meta Keywords</label>
                        <div class="col-md-12 border-bottom p-0">
                          <validation-provider
                            name="Keywords"
                            rules="required|max:200"
                            #default="{ errors }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            >
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FileTextIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                v-model.trim="companies.meta_keywords"
                                :state="errors.length > 0 ? false : null"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <div class="col-sm-12 pl-0 mt-10">
                          <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import mapboxgl from "mapbox-gl";
import axios from "axios";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Vue from "vue";
import { db, storage } from "@/main";
import firebase from "firebase";
import store from "@/store";

//Vee-Validate
import { ValidationProvider, ValidationObserver, validate } from "vee-validate";
import { required, email, confirmed, password, image, size, unique } from "@validations";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BFormText,
  BButton,
  BFormDatalist,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormFile,
  BFormSelect,
  BImg,
  BContainer,
} from "bootstrap-vue";

import Multiselect from "vue-multiselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Create",
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BImg,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email,
    confirmed,
    password,
    image,
    size,
    unique,
    Multiselect,
  },
  data: function () {
    return {
      companies: {},
      imageData: null,
      picture: null,
      uploadValue: 0,
      comLogoImg: "",
      comBackgroundImg: "",
      uploadValuech: 0,
      loading: false,
      locations: "",
      access_token: "pk.eyJ1IjoiaW50cm9zYnMiLCJhIjoiY2t2MHU4bWE4M2h1OTMwczcxMm03YmM0eSJ9.ocDaQEjuvL3rUd50oaB6Qw",
      center: [0, 0],
      map: {},
      name: "",
      email: "",
      asas: "",
      about: "",
      background_image: "",
      location: "",
      country: "",
      maplocation: "",
      postcode: "",
      phone_no: "",
      lat: "",
      lang: "",
      website: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      previewLogoImg: "",
      previewBackImg: "",
      subscriptions: [],
      category: "",
      category_id: [],
      coordinates: [],
      categories: [],
      subscription: "",
      finds: [],
      countries: [],
      country_code: "",
      countriesSelect: [],
    };
  },
  mounted() {
    this.createMap();
  },
  created: function () {
    this.getCompany();
  },
  methods: {
    getCompany() {
      this.countriesSelect = [];
      var id = this.$route.params.id;
      db.collection("companies")
        .doc(id)
        .get()
        .then((doc) => {
          this.companies = doc.data();
          this.center = doc.data().coordinates;
          this.finds = doc.data().outlets;
          this.countriesSelect.push({
            id: doc.data().country,
            name: doc.data().country_name,
          });
          this.previewBackImg = doc.data().background_image;
          this.previewLogoImg = doc.data().image;
        });
    },
       showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      });
    },
    submit: function () {
      var id = this.$route.params.id;
      var date = new Date().toLocaleString();

      // Ensure you have the images as base64 strings
      let imageData = this.previewLogoImg || this.companies.image;
      let backgroundImageData = this.previewBackImg || this.companies.background_image;

      // Handle 'stores' properly, possibly as an array of stores
      let stores = this.finds;

      db.collection("companies")
        .doc(id)
        .update({
          name: this.companies.name,
          email: this.companies.email,
          country_code: this.country_code,
          code: this.companies.code,
          about: this.companies.about,
             Currency: this.companies.Currency,
          image: imageData, // Update company logo
          background_image: backgroundImageData, // Update background image
          postcode: this.companies.postcode,
          stores: stores, // Add the stores information
          phone_no: this.companies.phone_no,
          website: this.companies.website,
          outlets: this.finds,
          meta_title: this.companies.meta_title,
          meta_description: this.companies.meta_description,
          meta_keywords: this.companies.meta_keywords,
          created: date,
          modified: date,
        })
        .then(() => {
          console.log('Company updated successfully');
             this.showNotification(
                          "Success, Added Successfully!",
                          "AlignJustifyIcon",
                          "Success"
                        );
                        this.$router.push("/companies/index");
        })
        .catch((error) => {
          console.error('Error updating company: ', error);
        });
    },

    onComLogoChange(event) {
      validate(event.target.files[0], "required|image|size:100", {
        name: "Company Logo Image",
      }).then((result) => {
        if (result.valid) {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            this.previewLogoImg = event.target.result;
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          this.previewLogoImg = "";
        }
      });
    },

    onComBackChange(event) {
      validate(event.target.files[0], "required|image|size:100", {
        name: "Company Background Image",
      }).then((result) => {
        if (result.valid) {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            this.previewBackImg = event.target.result;
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          this.previewBackImg = "";
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.main {
  padding: 45px 50px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-holder {
  width: 65%;
}

#map {
  height: 70vh;
}

.dislpay-arena {
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  width: 25%;
}

.coordinates-header {
  margin-bottom: 50px;
}

.coordinates-header h3 {
  color: #1f2a53;
  font-weight: 600;
}

.coordinates-header p {
  color: rgba(13, 16, 27, 0.75);
  font-weight: 600;
  font-size: 0.875rem;
}

.form-group {
  position: relative;
}

#bb {
  background-color: #3cbba6;
  /* border: 2px solid #03e1bc; */
  color: #edf1f5;
  padding: 5px 6px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  /* margin: 10px 30px; */
  cursor: pointer;
  border-radius: 50px;
}

.location-control {
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(31, 42, 83, 0.25);
  box-shadow: 0px 0px 10px rgba(73, 165, 198, 0.1);
  border-radius: 4px;
  padding: 0px 10px;
  width: 90%;
}

.location-control:focus {
  outline: none;
}

.location-btn {
  margin-top: 15px;
  padding: 10px 15px;
  background: #d80739;
  box-shadow: 0px 4px 10px rgba(73, 165, 198, 0.1);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
}

.location-btn:focus {
  outline: none;
}

.disabled {
  background: #db7990;
  cursor: not-allowed;
}

.copy-btn {
  background: #f4f6f8 0% 0% no-repeat padding-box;
  border: 1px solid #f4f6f8;
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  color: #5171ef;
  font-size: 0.875rem;
  font-weight: 500;
  height: 30px;
  padding: 0px 10px;
  cursor: pointer;
  right: 3.5%;
  top: 5%;
}

.copy-btn:focus {
  outline: none;
}

.dislpay-arena[data-v-0d798840] {
  width: 30%;
}

.coordinates-header h3[data-v-0d798840] {
  font-size: 20px;
}
</style>
